import React, { Component } from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import Daily from "../../components/BJOnTheDaily"

import s from "./bjonthedailyStyles.module.scss"

// import ShowWindowDimensions from "../components/ShowWindowDimensions"

class BJOnTheDaily extends Component {
   constructor(props) {
      super(props)

      this.state = {
         dailies: this.props.data.allInstaNode.nodes
            .filter(daily => daily.original)
            .map(daily => ({ ...daily, playing: false })),
      }

      this.dailyClick = this.dailyClick.bind(this)
      this.linkClick = this.linkClick.bind(this)
   }

   dailyClick(playPause, id) {
      if (playPause === "play") {
         this.setState(prevState => {
            return prevState.dailies.map(daily => {
               if (daily.id === id) {
                  daily.playing = true
               } else {
                  daily.playing = false
               }

               return daily
            })
         })
      } else if (playPause === "stop") {
         this.setState(prevState => {
            return prevState.dailies.map(daily => {
               if (daily.id === id) {
                  daily.playing = false
               }

               return daily
            })
         })
      }
   }

   linkClick(id) {
      // var scroll = new SmoothScroll()
      // scroll.animateScroll(document.querySelector("#" + id), null, {
      //    speed: 300,
      //    easing: "easeOutCubic",
      // })
      this.setState(prevState => {
         return prevState.dailies.map(daily => {
            if (daily.id === id) {
               daily.playing = true
            } else {
               daily.playing = false
            }

            return daily
         })
      })
   }

   render() {
      const animationStatus = {
         transitionStatus: this.props.transitionStatus,
         entry: this.props.entry,
         exit: this.props.exit,
      }

      const footerContent = {
         C2As: [
            {
               text: "Check out some of my recent work here.",
               link: "about-me",
            },
            { text: "Here are some other projects", link: "projects" },
         ],
         styles: {
            backgroundColor: "transparent",
         },
      }

      return (
         <div className={`mainContainer ${s.bjOnTheDailyContent}`}>
            <Layout
               title="BJ On The Daily"
               superTitle="Mr BJ Jackson / Projects"
               pageStyles={s}
               animationStatus={animationStatus}
               footerContent={footerContent}
               isProjectPage={true}
            >
               <p className={`${s.introduction} subheader`}>
                  From January 2019 I set myself a songwriting / production
                  challenge. I tried to write a new one minute piece of music
                  every day. I kept the challenge up until July, only
                  occasionally missing days here and there. It was a great
                  exercise and I intend to start again at the beginning of 2020.
                  I set the music to little videos I made from gifs I got from{" "}
                  <a
                     href="https://giphy.com/"
                     target="_blank"
                     rel="noopener noreferrer"
                  >
                     Giphy
                  </a>
                  .
               </p>
               <p>
                  Have a browse below. Or if you want a few recommendations to
                  get you started: for something chilled try{" "}
                  <a
                     href="#BvfYpR5gWrJ"
                     onClick={() => this.linkClick("BvfYpR5gWrJ")}
                  >
                     Angle Tarn
                  </a>{" "}
                  ,{" "}
                  <a
                     href="#Bwre3NVHe0d"
                     onClick={() => this.linkClick("Bwre3NVHe0d")}
                  >
                     Xavier
                  </a>{" "}
                  or{" "}
                  <a
                     href="#Bvqr3L8nKfC"
                     onClick={() => this.linkClick("Bvqr3L8nKfC")}
                  >
                     Mothering Sunday
                  </a>
                  , for some heavier electronica try{" "}
                  <a
                     href="#BygZDvSH-uE"
                     onClick={() => this.linkClick("BygZDvSH-uE")}
                  >
                     Drill Bit
                  </a>
                  ,{" "}
                  <a
                     href="#BwCBHG3nWG8"
                     onClick={() => this.linkClick("BwCBHG3nWG8")}
                  >
                     Keep Going
                  </a>
                  ,{" "}
                  <a
                     href="#Bv3hwjqnIh0"
                     onClick={() => this.linkClick("Bv3hwjqnIh0")}
                  >
                     New Tron
                  </a>{" "}
                  or{" "}
                  <a
                     href="#BwtxXDCHSBM"
                     onClick={() => this.linkClick("BwtxXDCHSBM")}
                  >
                     Rattlin
                  </a>{" "}
                  . My favourite sung ones are probably{" "}
                  <a
                     href="#BvDmPbJHE4j"
                     onClick={() => this.linkClick("BvDmPbJHE4j")}
                  >
                     Feeble
                  </a>
                  ,{" "}
                  <a
                     href="#BvHIzjcnemL"
                     onClick={() => this.linkClick("BvHIzjcnemL")}
                  >
                     30 Litre Love
                  </a>{" "}
                  ,{" "}
                  <a
                     href="#BvLG-fKHAQy"
                     onClick={() => this.linkClick("BvLG-fKHAQy")}
                  >
                     My New World
                  </a>{" "}
                  and{" "}
                  <a
                     href="#BwQSOs9nX1Q"
                     onClick={() => this.linkClick("BwQSOs9nX1Q")}
                  >
                     Traffic
                  </a>
                  .
               </p>
               <p>
                  To get future BJ On The Daily updates follow me on{" "}
                  <a
                     href="https://www.instagram.com/bjonthedaily/"
                     target="_blank"
                     rel="noopener noreferrer"
                  >
                     Instagram
                  </a>{" "}
                  or subscribe to me on{" "}
                  <a
                     href="https://www.youtube.com/channel/UCE2uIVSFLtgygqFrZ6xwd9A"
                     rel="noopener noreferrer"
                     target="_blank"
                  >
                     Youtube
                  </a>
                  . I also intend to release the good stuff on Spotify soon.
               </p>
               <hr ref={hr => (this.hrEl = hr)} />
               <div className="dailiesContainer">
                  {this.state.dailies.map((daily, index) => {
                     return (
                        <Daily
                           key={daily.id}
                           daily={daily}
                           dailyClick={this.dailyClick}
                        />
                     )
                  })}
               </div>
            </Layout>
         </div>
      )
   }
}

export default BJOnTheDaily

export const data = graphql`
   query Instagrams {
      allInstaNode(sort: { fields: timestamp, order: DESC }) {
         nodes {
            original
            timestamp
            preview
            mediaType
            id
            caption
         }
      }
   }
`
