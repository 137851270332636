import React, { Component } from "react"

export default class Daily extends Component {
   render() {
      const { daily } = this.props

      return (
         <div className="daily">
            {!daily.playing && (
               <img
                  src={daily.preview}
                  id={daily.id}
                  alt="BJ On The Daily cover art"
                  onClick={() => this.props.dailyClick("play", daily.id)}
               />
            )}
            {daily.playing && (
               <video
                  onClick={() => this.props.dailyClick("stop", daily.id)}
                  id={daily.id}
                  autoPlay
               >
                  <source src={daily.original} type="video/mp4" />
                  Your browser does not support the video tag.
               </video>
            )}
         </div>
      )
   }
}
